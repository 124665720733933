import React from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faFacebookF, faTwitter, faLinkedinIn, faWhatsapp, faRedditAlien} from "@fortawesome/free-brands-svg-icons"
import {
    FacebookShareButton,
    LinkedinShareButton,
    TwitterShareButton,
    WhatsappShareButton,
    RedditShareButton,
} from 'react-share'
import styled, {css} from "styled-components"

const ShareDiv = styled("div")`
    display: flex;
    flex-flow: row;
    flex-wrap: wrap;
    justify-content: space-between;
`

const brandColors = {
    facebook: "#3b5998",
    twitter: "#1da1f2",
    linkedin: "#0077b5",
    reddit: "#ff4500",
    whatsapp: "#128c7e",
}

const common = css`
    border: 1px solid;
    padding: 0.2rem 1rem;
    border-radius: 1rem;
    cursor: pointer;
    
    &:hover {
        color: #fff;
    }
`

const FacebookShareButtonDiv = styled(FacebookShareButton)`
    color: ${brandColors.facebook};
    ${common};
    &:hover {
        background: ${brandColors.facebook};
    }
`
const TwitterShareButtonDiv = styled(TwitterShareButton)`
    color: ${brandColors.twitter};
    ${common};
    &:hover {
        background: ${brandColors.twitter};
    }
`
const LinkedinShareButtonDiv = styled(LinkedinShareButton)`
    color: ${brandColors.linkedin};
    ${common};
    &:hover {
        background: ${brandColors.linkedin};
    }
`
const RedditShareButtonDiv = styled(RedditShareButton)`
    color: ${brandColors.reddit};
    ${common};
    &:hover {
        background: ${brandColors.reddit};
    }
`
const WhatsappShareButtonDiv = styled(WhatsappShareButton)`
    color: ${brandColors.whatsapp};
    ${common};
    &:hover {
        background: ${brandColors.whatsapp};
    }
`

const IconSpan = styled("span")`
    margin-right: 0.3rem;
`

const Share = ({postTitle, postUrl, twitterHandle}) => (
    <ShareDiv>
        <FacebookShareButtonDiv url={postUrl}>
            <IconSpan>
                <FontAwesomeIcon icon={faFacebookF}/>
            </IconSpan>
            <span>Facebook</span>
        </FacebookShareButtonDiv>

        <TwitterShareButtonDiv url={postUrl} title={postTitle} via={twitterHandle.split('@').join('')}>
            <IconSpan>
                <FontAwesomeIcon icon={faTwitter}/>
            </IconSpan>
            <span>Twitter</span>
        </TwitterShareButtonDiv>

        <LinkedinShareButtonDiv url={postUrl} title={postTitle}>
            <IconSpan>
                <FontAwesomeIcon icon={faLinkedinIn}/>
            </IconSpan>
            <span>LinkedIn</span>
        </LinkedinShareButtonDiv>

        <RedditShareButtonDiv url={postUrl} title={postTitle}>
            <IconSpan>
                <FontAwesomeIcon icon={faRedditAlien}/>
            </IconSpan>
            <span>Reddit</span>
        </RedditShareButtonDiv>

        <WhatsappShareButtonDiv url={postUrl} title={postTitle}>
            <IconSpan>
                <FontAwesomeIcon icon={faWhatsapp}/>
            </IconSpan>
            <span>WhatsApp</span>
        </WhatsappShareButtonDiv>
    </ShareDiv>
)

export default Share

