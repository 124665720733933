import React from "react"
import {Link, graphql} from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import {rhythm, scale} from "../utils/typography"
import Share from "../components/share"
import styled from "styled-components"

const Content = styled("div")`
    .anchor {
        box-shadow: none;
    }
`

class BlogPostTemplate extends React.Component {
    render() {
        const post = this.props.data.markdownRemark
        const siteTitle = this.props.data.site.siteMetadata.title
        const {previous, next} = this.props.pageContext
        const twitterHandle = this.props.data.site.siteMetadata.social.twitter
        const postUrl = `${this.props.data.site.siteMetadata.siteUrl}${post.fields.slug}`
        console.log(postUrl)

        return (
            <Layout location={this.props.location} title={siteTitle}>
                <SEO
                    title={post.frontmatter.title}
                    description={post.frontmatter.description || post.excerpt}
                />
                <h1>{post.frontmatter.title}</h1>
                <p
                    style={{
                        ...scale(-1 / 5),
                        display: `block`,
                        marginBottom: rhythm(1),
                        marginTop: rhythm(-1),
                    }}
                >
                    {post.frontmatter.date}
                </p>
                <Content dangerouslySetInnerHTML={{__html: post.html}}/>
                <hr
                    style={{
                        marginBottom: rhythm(1),
                    }}
                />
                <div>
                    <p>Having a question or suggestion about the article?</p>
                    <p>Tell me on Twitter where I'm <a href={`https://twitter.com/${twitterHandle}`} target="_blank"
                                                       rel="nofollow noopener noreferrer">@{twitterHandle}</a>!</p>
                </div>

                <hr
                    style={{
                        marginBottom: rhythm(1),
                    }}
                />

                <div>
                    <p>Help others understand Bitcoin and cryptocurrencies by sharing this post!</p>
                    <Share postTitle={post.frontmatter.title} postUrl={postUrl} twitterHandle={twitterHandle}/>
                </div>
                <ul
                    style={{
                        display: `flex`,
                        flexWrap: `wrap`,
                        justifyContent: `space-between`,
                        listStyle: `none`,
                        padding: 0,
                    }}
                >
                    <li>
                        {previous && (
                            <Link to={previous.fields.slug} rel="prev">
                                ← {previous.frontmatter.title}
                            </Link>
                        )}
                    </li>
                    <li>
                        {next && (
                            <Link to={next.fields.slug} rel="next">
                                {next.frontmatter.title} →
                            </Link>
                        )}
                    </li>
                </ul>
            </Layout>
        )
    }
}

export default BlogPostTemplate

export const pageQuery = graphql`
    query BlogPostBySlug($slug: String!) {
        site {
            siteMetadata {
                title
                author
                siteUrl
                social {
                    twitter
                }
            }
        }
        markdownRemark(fields: { slug: { eq: $slug } }) {
            id
            fields {
                slug
            }
            excerpt(pruneLength: 160)
            html
            frontmatter {
                title
                date(formatString: "MMMM DD, YYYY")
                description
            }
        }
    }
`
